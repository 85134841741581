:root {
  --width_phone_template: 60%;
  --color_palet_1: #293c74;
  --color_palet_2: #214f97;
  --color_palet_3: #3667ac;
  --color_palet_4: #487ebe;
  --color_palet_5: #609cd1;
  --color_palet_6: #78b3df;
  --color_palet_7: #92c7eb;
  --color_palet_8: #afdbf4;
  --color_palet_9: #cde9f8;
}

.cp-1 {
  color: var(--color_palet_1);
}
.cp-2 {
  color: var(--color_palet_2);
}
.cp-3 {
  color: var(--color_palet_3);
}
.cp-4 {
  color: var(--color_palet_4);
}
.cp-5 {
  color: var(--color_palet_5);
}
.cp-6 {
  color: var(--color_palet_6);
}
.cp-7 {
  color: var(--color_palet_7);
}
.cp-8 {
  color: var(--color_palet_8);
}
.cp-9 {
  color: var(--color_palet_9);
}

.bgcp-1 {
  background-color: var(--color_palet_1);
}
.bgcp-2 {
  background-color: var(--color_palet_2);
}
.bgcp-3 {
  background-color: var(--color_palet_3);
}
.bgcp-4 {
  background-color: var(--color_palet_4);
}
.bgcp-5 {
  background-color: var(--color_palet_5);
}
.bgcp-6 {
  background-color: var(--color_palet_6);
}
.bgcp-7 {
  background-color: var(--color_palet_7);
}
.bgcp-8 {
  background-color: var(--color_palet_8);
}
.bgcp-9 {
  background-color: var(--color_palet_9);
}

.page-content.pageContent {
  max-height: 100vh;
  overflow: auto;
}

.hidden {
  display: none !important;
}

.flow_container {
  margin: 0;
  padding: 0;
}

.flow_leftBlock {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.flow_leftBlock.mainScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flow_leftBlock.edit {
  padding: 10px;
}

.flow_rightBlock {
  padding: 20px;
  height: calc(100vh - 130px);
  overflow: auto;
  position: relative;
}

.flow_rightBlock .exit {
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  width: 40px;
  color: #bac2ca;
  border: 1px solid #bac2ca;
}

.title_flow {
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #606a72;
}

.action_block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: move;
  background-color: rgba(255, 255, 255, 0.8);
  height: 40px;
}
.action_block:hover {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.action_block p {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
.action_block.timing {
  background-color: #dfeaf4;
}

.flow_block {
  margin: 0 auto;
  border-radius: 5px;
  width: 300px;
  padding: 10px 10px;
  background-color: #d5e2ee;
  cursor: move;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.75);
}
.flow_block_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flow_block_header p {
  margin: 0;
  padding: 0;
}
.flow_block_status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flow_block_status .status {
  margin: 0;
  padding: 0;
  background-color: #a9ada9;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
}

.flow_block_status .status_data_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flow_block_status .status_data_list i {
  font-size: 14px;
  margin-left: 5px;
}

.line_flow {
  margin: 0 auto;
  position: relative;
  width: 300px;
}
.line_flow .line_top,
.line_flow .line_bottom {
  margin: 0 auto;
  width: 2px;
  background-color: #bac2ca;
  height: 25px;
}

.line_flow .middle {
  margin: 0 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #bac2ca;
  position: relative;
}

.line_flow .middle.active::before {
  position: absolute;
  content: "+";
  color: #fff;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #22be22;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.line_flow .middle.dragOver {
  width: 100%;
  background-color: #ebeeef;
  border: 1px dotted #bac2ca;
  border-radius: 5px;
  height: 70px;
}

.editBlock {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editBlock .contentEdit {
  border-bottom: 2px solid #a7a4a4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.editBlock .info {
  display: flex;
  /* padding-top: 10px; */
}

.editBlock .info p {
  color: #bfb3b3;
}

.editBlock .settings {
  border-bottom: 2px solid #a7a4a4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.editBlock .settings .setting_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.editBlock .settings .setting_item p {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
.editBlock .buttons {
  width: 100%;
  background-color: #ebebeb;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}
.editBlock .buttons button {
  width: 100%;
  background-color: #fff;
  padding: 5px;
}
.editBlock .buttons button:hover {
  background-color: #ebebeb;
}

.phone_template {
  margin: 0 auto;
  width: var(--width_phone_template);
  max-width: 360px;
  min-width: 300px;
  border: 10px solid #c4c9cf;
  border-radius: 60px;
  height: calc(var(--width_phone_template) * 1.65);
  max-height: 700px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.phone_template header {
  height: 70px;
  background-color: #eaedef;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.phone_template header p {
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 40px;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  width: 70%;
  margin-top: 20px;
}

.phone_template main {
  height: calc(100% - 70px - 90px);
  overflow: auto;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.phone_template main .buttons_test {
  display: flex;
  flex-direction: column;
}

.phone_template footer {
  height: 90px;
  background-color: #eaedef;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.phone_template footer div:nth-child(1),
.phone_template footer div:nth-child(2),
.phone_template footer div:nth-child(3) {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 30px;
}
.phone_template footer div:nth-child(2) {
  margin-left: 10px;
}
.phone_template footer div:nth-child(3) {
  width: 200px;
  border-radius: 15px;
  margin-left: 10px;
  position: relative;
}
.phone_template footer div:nth-child(3)::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #eaedef;
  right: 5px;
  top: 5px;
  border-radius: 50%;
}
.flows_create_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
}

.ItemFlowCreateCard {
  margin: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 300px;
  padding: 15px;
}
.ItemFlowCreateCard:hover {
  box-shadow: 0px 0px 10px 0px rgba(40, 51, 172, 0.75);
}

.ItemFlowCreateCard h2 {
  font-size: 14px;
}
.ItemFlowCreateCard h3 {
  font-size: 16px;
  font-weight: bold;
}
.ItemFlowCreateCard p {
  margin: 0;
  padding: 0;
}

.modal_create_left {
  background-color: #fff;
}

.modal_create_right {
  background-color: #e7e0e0;
}

.block_timeline {
  background-color: #fff;
  padding: 10px;
}
.block_timeline .filter {
  /* background-color: #f3efef; */
  text-align: center;
  margin-top: 10px;
}

.block_timeline .header {
  /* background-color: #f3efef; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* padding: 5px 0; */
}
.block_timeline .header p {
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}
.block_timeline .header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.block_timeline .header ul li {
  padding: 1px 4px;
  margin: 3px;
  border-radius: 5px;
  background-color: var(--color_palet_4);
}

.block_timeline .header ul li:hover,
.block_timeline .header ul li.active {
  cursor: pointer;
  transform: scale(1.2);
}

.block_timeline .header ul li i {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
}
.block_timeline .header ul li:first-child {
  background-color: #dbdddc;
}
.block_timeline .header ul li:first-child i {
  color: var(--color_palet_4);
}

/* .block_timeline .header ul li:nth-child(1) {
  background-color: #db554c;
}
.block_timeline .header ul li:nth-child(2) {
  background-color: #4188ca;
}
.block_timeline .header ul li:nth-child(3) {
  background-color: #68b162;
}
.block_timeline .header ul li:nth-child(4) {
  background-color: #eaaf55;
} */
.block_timeline .header ul li:last-child {
  background-color: #dbdddc;
  position: relative;
}
.block_timeline .header ul li:last-child i {
  color: #000;
}
.block_timeline .header ul li:last-child.load i {
  color: #dbdddc;
}
.block_timeline .header ul li:last-child .spinner-border {
  position: absolute;
  top: 7px;
  left: 7px;
  display: none;
  margin: 0;
  padding: 0;
}
.block_timeline .header ul li:last-child.load .spinner-border {
  display: block;
}

.block_timeline .content_timeline {
  position: relative;
  height: calc(100vh - 271px);
  /* height:  245px; */

  overflow: auto;
}

.content_timeline .line_tree {
  position: absolute;
  z-index: -1;
  width: 3px;
  background-color: #e7e1e1;
  top: 0;
  left: 40px;
  height: 100%;
}
.content_timeline .tree {
  background-color: transparent;
  padding: 15px 0;
  position: relative;
  z-index: 2;
}
.content_timeline .tree .months {
  background-color: transparent;
  list-style: none;
  margin: 0;
  padding: 0;
}
.content_timeline .tree .months li {
  background-color: transparent;
}
.content_timeline .tree .months li h3 {
  background-color: #91928c;
  display: inline;
  font-size: 12px;
  color: #fff;
  padding: 2px 20px;
}
.content_timeline .tree .months li .activities {
  background-color: transparent;
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
}
.content_timeline .tree .months li .activities li {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}
.content_timeline .tree .months li .activities li .icon {
  border-radius: 5px;
  padding: 4px;
  width: 40px;
  height: 40px;
  background-color: var(--color_palet_4);
}
.content_timeline .tree .months li .activities li .icon i {
  color: #fff;
  font-size: 31px;
  line-height: 32px;
}

.content_timeline .tree .months li .activities li .data {
  box-shadow: 0px 0px 10px 0px rgba(40, 51, 172, 0.75);
  padding: 10px 10px 10px 20px;
  margin-left: 15px;
  position: relative;
  width: calc(100% - 70px);
}
.content_timeline .tree .months li .activities li .data::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  left: 0;
  top: 20px;
  box-shadow: -1px 1px 1px -1px rgba(40, 51, 172, 0.75);
}
.content_timeline .tree .months li .activities li .data h2 {
  color: #db554c;
  font-size: 12px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}
.content_timeline .tree .months li .activities li .data h2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.content_timeline .tree .months li .activities li .data h3 {
  color: #728c9d;
  font-size: 12px;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
.content_timeline .tree .months li .activities li .data h3 i {
  color: #728c9d;
  margin-right: 5px;
}
.content_timeline .tree .months li .activities li .data h4 {
  color: #000;
  font-size: 10px;
  background-color: transparent;
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

.campaing_column {
  align-items: center;
  max-width: 250px;
}

.campaing_column_img img {
  width: 100%;
  height: auto;
}
.campaing_column_info h2 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 3px;
}
.campaing_column_info h3 {
  font-size: 12px;
  margin: 0;
  padding: 0;
  margin-bottom: 3px;
}
.campaing_column_info h4 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 3px;
}
.campaing_column_info .smart_sending {
  margin: 0;
  padding: 0;
  font-size: 12px;
  align-items: center;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0px;
}
.campaing_column_info .smart_sending:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(40, 51, 172, 0.75);
}
.campaing_column_info .smart_sending p {
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.section_config_campaing {
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(40, 51, 172, 1);
  border-radius: 10px;
  padding: 15px 0;
}
.estimated {
  display: block;
  position: absolute;
  z-index: 10;
  /* top: 0; */
  right: 30px;
  /* margin-right: 0px; */
  font-size: 11px;
  bottom: -8px;
}
.edit_sel_list_button {
  font-weight: 700;
  border: 1px solid #c3b8b8;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin-right: 20px;
}
.edit_sel_list_button:hover {
  background-color: #b4bfc7;
}
#search_sal_rep {
  border: 1px solid #ced4da;
}

#whom_selr_list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 200px;
  overflow: auto;
  padding-left: 15px;
}

#whom_selr_list li {
  border-bottom: 1px solid #ced4da;
  padding: 10px 5px;
  display: flex;
  align-items: center;
}

#whom_selr_list li.voice {
  padding: 0;
}

#whom_selr_list li p {
  font-size: 14px;
  margin: 0 0 0 10px;
}
.link_preview {
  box-shadow: 0px 0px 2px 0px rgba(40, 51, 172, 1);
}
.link_preview img {
  display: block;
  width: 60%;
  margin: 0 auto;
}

.link_preview .ttitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.link_preview .ttitle img {
  margin: 0;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.link_preview .ttitle h2 {
  width: 80%;
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
}

.send_campaing {
  position: fixed;
  border-radius: 50%;
  background-color: #4188ca;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  right: 20px;
  bottom: 100px;
  animation-duration: 1s;
  animation-name: send_campaing_;
  animation-iteration-count: infinite;
  /* animation-direction: alternate; */
}

@keyframes send_campaing_ {
  from {
    box-shadow: none;
    background-color: #4188ca;
  }
  to {
    box-shadow: 0px 0px 7px 0px rgb(119, 223, 15);
    background-color: #2f74b5;
  }
}

.send_campaing p {
  color: #fff;
  margin: 0;
  padding: 0;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
  /* animation-duration: 1s;
  animation-name: send_campaing_text;
  animation-iteration-count: infinite; */
}
@keyframes send_campaing_text {
  from {
    font-weight: normal;
  }
  to {
    font-weight: bolder;
  }
}

.send_campaing.launched {
  animation: none;
  background-color: #ca6841;
}

.page-content.overlay_block {
  position: relative;
}

.page-content.overlay_block::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 15;
}

.red_star {
  color: rgb(255, 0, 0);
  font-size: 16px;
}
.training_myai_label {
  display: inline-block;
  width: 120px;
}

.main-controls {
  /* padding-bottom: 0.7rem; */
  /* height: 170px; */
}
.sound-clips {
  /* margin-top: 20px; */
  /* box-shadow: inset 0 3px 4px rgb(0 0 0 / 70%); */
  /* background-color: rgb(0 0 0 / 10%); */
  /* height: calc(100% - 240px - 0.7rem); */
  /* overflow: auto; */
}
.sound-clips audio {
  width: 100%;
}

.visualizer {
  /* background-color: #22be22; */
  width: 100%;
  height: 80px;
}

.metismenu li.disable_menu_item {
  position: relative;
}
.metismenu li.disable_menu_item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  /* background: #00000060; */
  z-index: 1;
}

.wrapper {
  margin-top: 20px;
}

.customOptionSelect {
  padding: 5px 10px;
  border-bottom: 1px solid #333;
}
.customOptionSelect.voice {
  border: none;
}
.customOptionSelect:hover {
  background-color: var(--color_palet_5);
  cursor: pointer;
}

.customOptionSelect.user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.customOptionSelect.user p {
  margin: 0;
  /* width: 150px; */
  font-size: 15px;
}
.customOptionSelect.user span {
  font-size: 12px;
  display: inline-block;
  text-overflow: clip;
}
.customOptionSelect.user .assign {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  font-weight: 600;
  display: none;
}
.customOptionSelect.user:hover .assign {
  display: block;
}

.voice_mail_container {
  height: calc(100vh - 270px);
  overflow: auto;
}

.voicemail_item.listened,
.voicemail_item.listened td {
  background-color: #fff;
}

.listingBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.listingBtn.isListen {
  background-color: #00ff00;
}

.mic_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ac4c4c;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cbcbcb;
  cursor: pointer;
}

.bread_cust:not(.active) {
  cursor: pointer;
}
.bread_cust:not(.active):hover {
  font-weight: 500;
}
.amount_input {
  width: 30px;
}
.amount_input input {
  border: none;
  width: 100%;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  border-color: #666666;
  border-radius: 0.25rem;
}
.message_text {
  text-align: left;
  word-wrap: break-word;
  word-break: break-all;
}
#list_products > tr > td {
  background-color: transparent;
}
#list_products > tr > td:nth-child(1) {
  width: 10px;
  padding: 0;
  padding-left: 10px;
}
#list_products > tr > td:nth-child(2) {
  width: 70px;
}
#list_products > tr > td:nth-child(3) {
  max-width: 220px;
}
#list_products > tr > td:nth-child(4) {
  width: 50px;
}

.order-logo {
  height: 70px;
}
.order-logo img {
  height: 100%;
  margin: 0 auto;
}

.order-content {
  max-height: calc(100vh - 140px);
  overflow: auto;
}

.order-bottom {
  height: 70px;
}
.popover {
  position: absolute;
  z-index: 200;
}
.coverColorPicker {
  position: fixed;
  inset: 0px;
}

.paymentitem {
  height: 200px;
  cursor: pointer;
}
.paymentitem:hover {
  background-color: rgb(239, 242, 247);
}

.paymentitem img {
  width: auto;
  height: 100%;
  max-width: 100%;
}

.pin_s {
  /* position: absolute; */
  cursor: pointer;
  padding: 5px 5px 0 5px;
}

.pin_sm {
  position: absolute;
  cursor: pointer;
  padding: 0px 5px 0 5px;
  top: 0;
  right: 0;
}
.order_number {
  cursor: pointer;
}
.order_number:hover {
  text-decoration: underline;
}

.modal-dialog.mess_detail_modal > .modal-content {
  height: 90vh;
}

.message_email {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  width: 100%;
  background-color: #f7f9fa;
  padding: 5px 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.message_email .direction {
  background-color: #dddddd;
  padding: 1px 5px;
  border-radius: 5px;
  margin-right: 15px;
}

.message_email .contentMes {
  width: 100%;
}

.message_email .subject {
  margin: 0;
  padding: 0;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
}
.message_email .subject .isthread {
  position: absolute;
  margin: 0;
  padding: 0;
  background-color: #dddddd;
  padding: 1px 5px;
  border-radius: 5px;
  top: 0;
  right: 0;
  font-size: 10px;
}

.message_email:hover {
  background-color: #bbe2f6;
}

.message_email .checks {
  width: 25px;
}

.message_email .preview {
  margin: 0;
  padding: 0;
  text-wrap: wrap;
  /* width: 300px; */
  /* overflow: hidden; */
  /* text-wrap: nowrap; */
  /* text-overflow: clip; */
}

.message_email .timestamp {
  text-align: right;
  margin-top: 10px;
}

.frameMessage {
  width: 100%;
  height: 85%;
}
.attachments_list {
  list-style: none;
  display: flex;
}

.attachments_list li {
  width: 120px;
  height: 80px;
  border: 1px solid #333333;
  margin-right: 5px;
  position: relative;
}
.attachments_list li img {
  width: 100%;
  height: auto;
}
.attachments_list li p {
  text-align: center;
  margin: 0;
  font-size: 10px;
}

.attachments_list li .overlay {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}

.attachments_list li:hover .overlay {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.attachments_list li:hover .overlay a {
  color: #fff;
}
.handled {
  color: #214f97;
}
.handled:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* home page */

.home_container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url(/src/assets/images/coolCloud/images.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.home_container header {
  padding: 20px;
  background-color: #f2f2f580;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
}
.home_container header .logo {
  height: 66px;
}
.home_container header .logo img {
  height: 100%;
  width: auto;
  display: none;
}
.home_container header .logo img.little {
  display: block;
}

.home_container header .auth {
  display: flex;
  align-items: center;
}
.home_container header .auth .login,
.home_container header .auth .signup {
  border: 1px solid var(--color_palet_2);
  color: var(--color_palet_2);
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 20px;
}
.home_container header .auth .login:hover,
.home_container header .auth .signup:hover {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
}

.home_container main {
  display: flex;
  color: #fff;
  /* margin-top: 20px; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  /* padding: 90px 0; */
  /* max-height: 100vh; */
}

.home_container main .text {
  width: 100%;
  padding-left: 40px;
}
.home_container main .biglogo {
  width: 70%;
  text-align: center;
  /* display: none; */
}
.home_container main .biglogo img {
  width: 100%;
  height: auto;
}

.home_container footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  background-color: #f2f2f580;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home_container footer .prp {
  width: 200px;
}
.home_container footer .address p {
  margin: 0;
}

@media screen and (min-width: 600px) {
  .home_container header .logo img.big {
    display: block;
  }
  .home_container header .logo img.little {
    display: none;
  }
  .home_container main {
    flex-direction: row;
    padding-top: 5%;
  }

  .home_container main .text {
    width: 45%;
  }
  .home_container main .biglogo {
    width: 40%;
    /* display: block; */
  }
}
